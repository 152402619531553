import React from "react"
import "../scss/blog-post.scss"

// Components
import Layout from "../components/layout"
import TopSpacer from "../components/top-spacer/top-spacer"

// SEO
import SEO from "../seo/main.js"
import Breadcrumb from "../seo/breadcrumb"

export default function Software(props) {
  const softwareObject = props.pageContext
  const crumbArray = [
    { title: "Software", url: "/software/" },
    { title: softwareObject.title, url: softwareObject.url },
  ]

  return (
    <>
      {softwareObject ? (
        <Layout>
          <SoftwarePost softwareObject={softwareObject} />
          <Breadcrumb location={props.location} array={crumbArray} />
        </Layout>
      ) : null}
    </>
  )
}

const SoftwarePost = props => {
  const { softwareObject } = props
  
  return (
    <>
    <SEO
      title={softwareObject.seo_title}
      description={softwareObject.seo_description}
      article
    />
    <TopSpacer />
      <div className="headline-area" key="headline-area">
        <h1>{softwareObject.title}</h1>
      </div>
      <div className="summary-area" key="summary-area">
        <div className="featured">
          <div className="text green">
            <h3>
              <b>Short information about </b>
              {softwareObject.title}
            </h3>
            <ul>
            <li>What is {softwareObject.title} used for?<br/><span>{softwareObject.header.specifications.used_for}</span></li><br/>
            <li>Is their a free plan available?<br/><span>{softwareObject.header.specifications.free_tier}</span></li><br/>
            <li>How much does the Paid plans starts from?<br/><span>{softwareObject.header.specifications.premium}</span></li><br/>
            </ul>
          </div>
          <div className="primary-cta outbound"><a href={softwareObject.header.specifications.url} target="_blank" rel="noreferrer">Go to website</a></div>
          <div className="secondary-cta outbound"><a href={softwareObject.header.specifications.documentation} target="_blank" rel="noreferrer">Documentation</a></div>
        </div>
        <div className="summary">
          <p>
            <span>
              <b>How you can use {softwareObject.title}</b>
            </span>
          </p>
          {softwareObject.header.description.map((text, i) => (
            <p key={"foreword_" + i}>{text}</p>
          ))}
        </div>
      </div>
      {/* <FeaturedPosts title={"Featured Blog Posts using Zapier"} /> */}
    </>
  )
}